import styled from "styled-components";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <Root>
      <Address>
        <div>(주)팀엘리시움&nbsp;&nbsp;|&nbsp;&nbsp;대표 김원진, 박은식&nbsp;&nbsp;|&nbsp;&nbsp;</div>
        <div>서울특별시 서초구 효령로29길 77, 네오피스빌딩 2층</div>
      </Address>
      <Copyright>CopyrightⓒTeamElysium Inc. All Rights Reserved.</Copyright>

      <PolicyLink>
        <Link to={`${process.env.PUBLIC_URL}/policy`}>개인정보처리방침</Link>
      </PolicyLink>

      <Contact>
        <Href href="#" target="_blank" rel="noopener noreferrer">
          데모신청 및 견적 문의
        </Href>
        <Href href="https://www.pomchecker.com/reference/" target="_blank" rel="noopener noreferrer">
          도입 병원 보기
        </Href>
        <Href href="https://pf.kakao.com/_mQxaiC" target="_blank" rel="noopener noreferrer">
          카카오톡 상담
        </Href>
        <Href href="https://www.pomchecker.com/blog/" target="_blank" rel="noopener noreferrer">
          블로그 바로가기
        </Href>
      </Contact>
    </Root>
  );
};

const Root = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  font-size: 14px;
  padding: 55px 0;

  @media screen and (max-width: 450px) {
    gap: 20px;
    align-items: baseline;
    font-size: 12px;
    padding: 40px 0 40px 30px;
  }
`;

const Address = styled.div`
  display: flex;
  flex-direction: row;

  div {
    color: #000000;
  }

  @media screen and (max-width: 450px) {
    flex-direction: column;
    gap: 5px;
  }
`;

const Copyright = styled.div`
  color: #000000;
`;

const PolicyLink = styled.div`
  a {
    text-decoration: none;
    color: #000000;

    &:hover {
      cursor: pointer;
      opacity: 0.8;
    }
  }
`;

const Contact = styled.div`
  display: flex;
  flex-direction: row;
  gap: 30px;

  @media screen and (max-width: 450px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px 20px;
  }
`;

const Href = styled.a`
  color: #000000;
  font-size: 14px;
  text-decoration-line: none;

  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }

  @media screen and (max-width: 450px) {
    font-size: 12px;
  }
`;

export default Footer;
