import styled from "styled-components";
import line from "../images/3_ic_line.png";
import report from "../images/report.png";

const SectionThird = () => {
  return (
    <Root>
      <Number>1</Number>
      <Title>
        편리한 검사방식 및 <span>체계적인 환자관리</span>
      </Title>
      <Text>
        근골격계 진단 프로세스에 최적화된 검사 방식과 체계적인 환자 관리가 가능한 사용자 인터페이스를 제공합니다.
      </Text>
      <Line src={line} alt="폼체커 구분선" />

      <Number>2</Number>
      <Title>정확하고 빠른 측정</Title>
      <Text>
        별도의 센서(마커) 탈착 없이 <span>빠른 측정이 가능합니다.</span>
      </Text>
      <Line src={line} alt="폼체커 구분선" />

      <Number>3</Number>
      <Title>결과 데이터 분석</Title>
      <Text>치료 전 &middot; 후 데이터를 비교하여 지속적인 팔로우업이 가능하며 상담 시 용이하게 활용 가능합니다.</Text>

      <Content>
        <SubTitle>자세평가(Posture)</SubTitle>
        <SubText>
          정면, 측면 분석을 통해 체형을 측정하고, 분석할 수 있습니다.
          <div>비급여 EZ774 - 동작분석(생역학검사)</div>
        </SubText>
      </Content>
      <Content>
        <SubTitle>관절가동범위검사(ROM)</SubTitle>
        <SubText>
          어깨, 팔꿈치, 골반의 관절가동범위를 검사하여 객관적으로 평가하고 수술 및 치료 전 후를 팔로우업 할 수 있습니다.
        </SubText>
      </Content>

      <Line src={line} alt="폼체커 구분선" />

      <Number>4</Number>
      <Title>결과 리포트</Title>
      <Text>
        측정 결과 데이터를 분석하여, 개인 맞춤 운동 추천을 해주고 카카오채널을 통해 알림톡을 전송하여 병원 홍보 효과를
        높여줍니다.
      </Text>

      <ReportImage src={report} alt="폼체커 리포트" />
    </Root>
  );
};

const Root = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #f8f8f8;
  padding: 50px 0;

  @media screen and (max-width: 450px) {
    height: 2100px;
  }
`;

const Number = styled.div`
  font-size: 44px;
  font-weight: 800;
`;

const Title = styled.div`
  font-size: 24px;
  font-weight: 800;
  margin-top: 10px;

  @media screen and (max-width: 450px) {
    width: 170px;
    font-size: 20px;
    text-align: center;
    line-height: 32px;
    margin-top: 20px;
  }
`;

const Text = styled.div`
  width: 100%;
  color: #000000;
  font-size: 18px;
  text-align: center;
  line-height: 26px;
  margin-top: 15px;
  word-break: keep-all;

  @media screen and (max-width: 850px) {
    max-width: 600px;
    margin: 15px 60px 0;
  }

  @media screen and (max-width: 450px) {
    max-width: 280px;
    font-size: 14px;
    line-height: 26px;
    margin: 5px 0 0;
  }
`;

const SubTitle = styled.div`
  color: rgba(34, 46, 56, 0.8);
  text-align: center;
  font-weight: 800;
  margin: 40px 0 10px;

  @media screen and (max-width: 450px) {
    font-size: 16px;
  }
`;

const SubText = styled.div`
  color: rgba(0, 0, 0, 0.8);
  text-align: center;
  line-height: 26px;
  width: 100%;

  span:nth-child(2) {
    margin-top: 10px;
  }

  @media screen and (max-width: 850px) {
    max-width: 600px;
    margin: 15px 60px 0;
  }

  @media screen and (max-width: 450px) {
    width: 280px;
    font-size: 14px;
    line-height: 22px;
    margin: 0 auto;

    div:nth-child(2) {
      margin: 0;
    }
  }
`;

const Content = styled.div`
  width: 100%;
  color: black;
  font-size: 16px;
  text-align: center;

  @media screen and (max-width: 450px) {
    margin-top: 20px;
  }
`;

const Line = styled.img`
  max-width: 924px;
  margin: 50px 0 30px 0;

  @media screen and (max-width: 1280px) {
    width: 100%;
    margin: 50px 0 30px;
  }

  @media screen and (max-width: 850px) {
    width: 80%;
    height: 15px;
    margin: 50px 60px 30px;
  }

  @media screen and (max-width: 450px) {
    width: 80%;
    margin: 120px 0;
  }
`;

const ReportImage = styled.img`
  width: 607px;
  height: 317px;
  margin-top: 50px;

  @media screen and (max-width: 850px) {
    width: 280px;
    height: 146px;
  }
  @media screen and (max-width: 450px) {
    margin-top: 30px;
  }
`;
export default SectionThird;
