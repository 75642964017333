import { useState, useEffect } from "react";
import styled from "styled-components";
import top from "../images/top.png";
import React from "react";

const TopButton = () => {
  const [ScrollY, setScrollY] = useState(0);
  const [showButton, setShowButton] = useState(false);

  const handleFollow = () => {
    setScrollY(window.pageYOffset);
  };

  const watch = () => {
    window.addEventListener("scroll", handleFollow);
  };

  useEffect(() => {
    watch();
    return () => {
      window.removeEventListener("scroll", handleFollow);
    };
  });

  useEffect(() => {
    if (ScrollY < 400) {
      setShowButton(false);
    } else {
      setShowButton(true);
    }
  }, [ScrollY]);

  const handleTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    setScrollY(0);
    setShowButton(false);
  };

  return showButton && <Top src={top} class="폼체커 최상단으로 끌어올리는 버튼" onClick={handleTop} />;
};

const Top = styled.img`
  width: 60px;
  height: 60px;
  position: fixed;
  right: 340px;
  bottom: 50px;

  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }

  @media screen and (max-width: 1280px) {
    right: 174px;
  }
  @media screen and (max-width: 820px) {
    right: 35px;
  }
`;

export default TopButton;
