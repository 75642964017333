import styled from "styled-components";
import React from "react";

const Video = React.forwardRef((props, ref) => {
  return (
    <Root ref={ref}>
      <Youtube
        width="800"
        height="450"
        src="https://www.youtube.com/embed/EPnLYMMVJKM"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></Youtube>
    </Root>
  );
});

const Root = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 50px 0;

  @media screen and (max-width: 1000px) {
    padding: 80px 0;
  }
  @media screen and (max-width: 450px) {
    padding: 44px 0;
  }
`;

const Youtube = styled.iframe`
  border: none;

  @media screen and (max-width: 1000px) {
    width: 600px;
    height: 330px;
  }
  @media screen and (max-width: 450px) {
    width: 100%;
    height: 203px;
  }
`;

export default Video;
