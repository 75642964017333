import { createGlobalStyle } from "styled-components";

import Light from "./NanumSquare/NanumSquareL.otf";
import Regular from "./NanumSquare/NanumSquareR.otf";
import Bold from "./NanumSquare/NanumSquareEB.otf";

const GlobalFontStyle = createGlobalStyle`
    @font-face {
        font-family: "NanumSquare";
        src: url(${Light}) format("opentype");
        font-weight: 300;
    }

    @font-face {
        font-family: "NanumSquare";
        src: url(${Regular}) format("opentype");
        font-weight: 400;
    }
    
    @font-face {
        font-family: "NanumSquare";
        src: url(${Bold}) format("opentype");
        font-weight: 800;
    }

    * {
        color: #222E38;
        font-family: "NanumSquare", sans-serif;
        box-sizing: border-box;
    }
`;

export default GlobalFontStyle;
