import styled from "styled-components";
import logo from "../images/logo.png";

const Header = ({ contactScrollTo }) => {
  return (
    <Root>
      <Logo src={logo} alt="팀엘리시움로고" />
      <Menu onClick={contactScrollTo}>CONTACT</Menu>
    </Root>
  );
};

const Root = styled.div`
  width: 100%;
  height: 68px;
  position: fixed;
  top: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  padding: 0 20% 0 25%;
  z-index: 2;

  @media screen and (max-width: 1280px) {
    padding: 0 15% 0 13%;
  }
  @media screen and (max-width: 820px) {
    padding: 0 58px;
  }
  @media screen and (max-width: 450px) {
    padding: 13px 24px;
  }
`;

const Logo = styled.img`
  width: 80px;
  height: 42px;
`;

const Menu = styled.div`
  font-size: 14px;
  font-weight: 800;

  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
`;

export default Header;
