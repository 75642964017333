import styled from "styled-components";
import fast from "../images/2_ic_1.png";
import convenient from "../images/2_ic_2.png";
import claim from "../images/2_ic_3.png";
import space from "../images/2_ic_4.png";

const SectionFirst = () => {
  return (
    <Root>
      <Wrap>
        <Box>
          <img src={fast} alt="폼체커 빠르고 정확한 검사" />
          <Title>빠르고 정확한 검사</Title>
          <Content>자동관절인식을 통한 실시간 측정</Content>
        </Box>
        <Box>
          <img src={convenient} alt="폼체커 편리한 측정" />
          <Title>편리한 측정</Title>
          <Content>마커 없는 최신 측정 기술</Content>
        </Box>
        <Box>
          <img src={claim} alt="폼체커 수가 청구 가능" />
          <Title>수가 청구 가능</Title>
          <Content>법정 급여/비급여 코드 제공</Content>
        </Box>
        <Box>
          <img src={space} alt="폼체커 효율적인 공간활용" />
          <Title>효율적인 공간활용</Title>
          <Content>이동이 자유로운 의료기기</Content>
        </Box>
      </Wrap>
    </Root>
  );
};

const Root = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 110px 0;

  @media screen and (max-width: 1280px) {
    padding: 155px 0;
  }
  @media screen and (max-width: 820px) {
    padding: 65px 0;
  }
  @media screen and (max-width: 450px) {
    padding: 50px 0;
  }
`;

const Wrap = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 80px 250px;

  @media screen and (max-width: 820px) {
    gap: 70px 104px;
  }
  @media screen and (max-width: 450px) {
    display: flex;
    flex-direction: column;
    gap: 25px;
  }
`;

const Box = styled.div`
  text-align: center;

  img {
    width: 60px;
    height: 60px;
  }

  @media screen and (max-width: 450px) {
    img {
      width: 40px;
      height: 40px;
    }
  }
`;

const Title = styled.div`
  font-size: 24px;
  font-weight: 800;
  margin-top: 20px;

  @media screen and (max-width: 450px) {
    font-size: 16px;
    margin-top: 10px;
  }
`;

const Content = styled.div`
  font-size: 18px;
  margin-top: 10px;
  @media screen and (max-width: 450px) {
    font-size: 14px;
  }
`;

export default SectionFirst;
