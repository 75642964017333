import styled from "styled-components";
import call from "../images/call.svg";
import video from "../images/video.svg";
import cover1 from "../images/cover1.png";
import cover2 from "../images/cover2.png";
import cover3 from "../images/cover3.png";
import cover4 from "../images/cover4.png";
import cover5 from "../images/cover5.png";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Cover = ({ contactScrollTo, videoScrollTo }) => {
  const settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 4000,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    draggable: false,

    responsive: [
      {
        breakpoint: 450,
        settings: {
          dots: false,
          autoplay: true,
          autoplaySpeed: 4000,
          infinite: true,
        },
      },
    ],
  };

  const items = [
    { id: "image1", url: cover1, alt: "폼체커 의료기기 사진" },
    { id: "image2", url: cover2, alt: "폼체커 체형측정 사진" },
    { id: "image3", url: cover3, alt: "폼체커 체형분석 사진" },
    { id: "image4", url: cover4, alt: "폼체커 의사 사진" },
    { id: "image5", url: cover5, alt: "폼체커 환자 사진" },
  ];

  return (
    <Root>
      <Left>
        <TitleWrap>
          <SubTitle>체형측정 체형분석의 모든 것.</SubTitle>
          <Title>
            POM CHECKER<Symbol>®</Symbol>
          </Title>
        </TitleWrap>

        <Content>
          <div>폼체커</div>
          <div>
            3D 센서 기반 관절가동범위 측정 및
            <br />
            신체 불균형 분석 의료기기
          </div>
        </Content>

        <ButtonWrap>
          <CallButton data-tally-open="w2aP2g" data-tally-layout="modal" data-tally-hide-title="1" data-tally-emoji-text="👋" data-tally-emoji-animation="wave">
            <img src={call} alt="폼체커 상담 전화 버튼" />
            상담 신청 하기
          </CallButton>

          <VideoButton onClick={videoScrollTo}>
            <img src={video} alt="폼체커 소개 영상 버튼" />
            소개 영상 보기
          </VideoButton>
        </ButtonWrap>
      </Left>

      <Right>
        <StyledSlider {...settings}>
          {items.map((item) => {
            return <CoverImage key={item.id} src={item.url} />;
          })}
        </StyledSlider>
      </Right>
    </Root>
  );
};

const Root = styled.div`
  width: 100%;
  height: 720px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f8f8f8;
  position: relative;

  @media screen and (max-width: 850px) {
    height: 480px;
    margin-top: 70px;
  }
  @media screen and (max-width: 450px) {
    height: 573px;
    display: block;
    background-color: none;
  }
`;

const Left = styled.div`
  position: absolute;
  top: 35%;
  left: 25%;
  z-index: 1;

  @media screen and (max-width: 1280px) {
    left: 14%;
  }
  @media screen and (max-width: 850px) {
    left: 8%;
  }
  @media screen and (max-width: 450px) {
    width: 100%;
    height: 100%;
    position: relative;
    top: 0;
    left: 0;
  }
`;

const TitleWrap = styled.div`
  display: block;

  @media screen and (max-width: 450px) {
    position: absolute;
    top: 35px;
    left: 30px;
  }
`;

const SubTitle = styled.div`
  font-weight: 800;
  font-size: 36px;

  @media screen and (max-width: 850px) {
    font-size: 24px;
  }
  @media screen and (max-width: 450px) {
    font-size: 20px;
  }
`;

const Title = styled.div`
  position: relative;
  font-weight: 800;
  font-size: 48px;
  margin-top: 15px;

  @media screen and (max-width: 850px) {
    font-size: 32px;
  }
  @media screen and (max-width: 450px) {
    font-size: 24px;
  }
`;

const Symbol = styled.span`
  position: absolute;
  top: -4px;
  font-size: 28px;
  font-weight: 800;
  padding-left: 5px;

  @media screen and (max-width: 850px) {
    font-size: 18px;
    top: 0;
  }
`;

const Content = styled.div`
  display: flex;
  gap: 20px;
  margin-top: 20px;
  line-height: 26px;

  div:first-of-type {
    font-weight: 800;
  }

  @media screen and (max-width: 850px) {
    font-size: 14px;

    div:first-of-type {
      font-size: 16px;
    }
  }
  @media screen and (max-width: 450px) {
    position: absolute;
    bottom: 150px;
    left: 30px;
    flex-direction: column;
    gap: 0;
  }
`;

const ButtonWrap = styled.div`
  display: flex;
  gap: 10px;
  margin-top: 40px;

  button {
    width: 142px;
    height: 40px;
    font-size: 14px;
    line-height: 26px;
    border none;
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 7px;
    color: #fff;

    &:hover {
      cursor: pointer;
      opacity: 0.9;
    }
  }
  
  img {
    width: 16px;
    height: 16px;
  }

  @media screen and (max-width: 850px) {
    flex-direction: column;
    margin-top: 97px;
  }
  @media screen and (max-width: 450px) {
    flex-direction: column;
    gap: 10px;
    position: absolute;
    bottom: 20px;
    left: 30px;
    margin: 0;
  }
`;

const CallButton = styled.button`
  background-color: #222e38;
`;

const VideoButton = styled.button`
  background-color: #4b657c;
`;

const Right = styled.div`
  width: 660px;
  height: 720px;
  position: absolute;
  top: 0;
  right: 10%;
  overflow: hidden;
  clip-path: polygon(33% 0, 100% 0, 100% 100%, 0% 100%);

  @media screen and (max-width: 1280px) {
    right: 0;
  }
  @media screen and (max-width: 850px) {
    width: 440px;
    height: 480px;
  }
  @media screen and (max-width: 450px) {
    clip-path: none;
    width: 100%;
    height: 573px;
  }
`;

const StyledSlider = styled(Slider)`
  .slick-prev,
  .slick-next {
    display: none;
  }

  .slick-dots {
    width: 150px;
    left: 50%;
    bottom: 10px;
    transform: translate(-50%, -50%);
    padding: 0;
    margin: 0;
  }

  .slick-dots li {
    width: 10px;
    height: 10px;
    margin: 0 5px;
    display: inline-block;
  }

  .slick-dots li button {
    width: 10px;
    height: 10px;
  }

  .slick-dots li button:before {
    width: 10px;
    height: 10px;
    color: #ffffff;
    opacity: 1;
  }

  .slick-dots li.slick-active button:before {
    color: #222e38 !important;
  }

  li {
    margin: 0;
    padding: 0;
  }

  img {
    @media screen and (max-width: 450px) {
      width: 100%;
      height: 573px;
      opacity: 0.5;
      background: rgba(255, 255, 255, 0.5);
    }
  }
`;

const CoverImage = styled.img`
  object-fit: cover;

  @media screen and (max-width: 450px) {
    opacity: 0.5;
  }
`;

export default Cover;
